import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/assumable-loans-san-diego");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Assumable Loans_ A Game-Changer in the San Diego Housing Market.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                The housing market can feel daunting on its own. Factor in low inventory and rising
                interest rates, and it can feel outright impossible.
              </CommonParagraph>
              <CommonParagraph>
                But there's a potential solution called assumable loans. These loans allow buyers to
                take on the seller's existing mortgage—often at a much lower interest rate than
                what's currently available.
              </CommonParagraph>
              <CommonParagraph>
                If you’re new to this creative financing solution, keep reading. We’ll explain how
                it works and how it can benefit buyers and sellers.
              </CommonParagraph>
              <Heading type="h2">What Are Assumable Loans?</Heading>
              <CommonParagraph>
                In a nutshell, an assumable mortgage loan is one in which a buyer “assumes”
                responsibility for the seller’s existing mortgage rather than obtaining their own
                financing.
              </CommonParagraph>
              <CommonParagraph>
                If you’re a buyer{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego/"
                  text="purchasing a property"
                />{" "}
                with an assumable loan, you’ll take over the seller’s existing mortgage, including
                their:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>Balance</li>
                <li>Terms</li>
                <li>Interest rate</li>
              </ul>
              <CommonParagraph>
                While not all home loans are assumable, many VA, USDA, and FHA options are.
                Conventional loans with certain terms may also be assumable.
              </CommonParagraph>
              <Heading type="h2">Benefits for Buyers</Heading>
              <Heading type="h3">Lower Interest Rates</Heading>
              <CommonParagraph>
                The ability to secure a lower interest rate compared to current market rates is one
                of the most significant advantages for buyers. That’s especially true in Southern
                California, where real estate prices tend to stay high. Assumable home loans in San
                Diego could translate to massive savings over the life of the loan. With today’s
                rates hovering around 6%, think of all you’d save if you were able to assume a loan
                with a 3% rate!
              </CommonParagraph>
              <Heading type="h3">Lower Monthly Payments</Heading>
              <CommonParagraph>
                Interest isn’t the only area of potential savings for assumable home loans. They
                often come with lower payments as well, making homeownership that much more
                affordable for buyers, especially those{" "}
                <BlogLink
                  url="https://selbysellssd.com/blog/first-time-home-buyer-what-to-know"
                  text="entering the market for the first time"
                />
                .
              </CommonParagraph>
              <Heading type="h2">Benefits for Sellers</Heading>
              <Heading type="h3">Enhanced Appeal</Heading>
              <CommonParagraph>
                Assumable loans in California can attract a broader pool of buyers, especially those
                who might otherwise be hesitant to move to areas that may come with a higher cost of
                living. This increase in buyer interest can mean fewer negotiations and faster
                closings.
              </CommonParagraph>
              <Heading type="h3">Potential for Higher Offers</Heading>
              <CommonParagraph>
                Low interest rates are attractive, especially considering where the market currently
                stands. Homes that come with assumable loans with low interest rates could command
                higher prices, considering the value of reduced interest rates and lower monthly
                payments.
              </CommonParagraph>
              <CommonParagraph>
                Take our recent client, for example, who had a home in a competitive area that they
                were ready to sell. Their assumable loan set their property apart, immediately
                attracting attention. After all was said and done, The Selby Team was able to
                leverage that unique selling point to secure an additional $75,000 for our
                client—all because they had an assumable loan.
              </CommonParagraph>
              <Heading type="h3">Streamlined Transactions</Heading>
              <CommonParagraph>
                <BlogLink url="https://selbysellssd.com/sell-your-san-diego-home/" text="Sellers" />{" "}
                don’t need to refinance or pay off existing loans, which could make the entire
                transaction more smooth. Such a streamlined process may also reduce the potential
                for delays.
              </CommonParagraph>
              <Heading type="h2">Important Assumable Loan Considerations</Heading>
              <CommonParagraph>
                While assumable loans do come with many advantages, there are potential drawbacks to
                consider, as is true with any type of financing option. Here are a few things to
                keep in mind when wondering if an assumable loan is the route for you:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  Qualifying process: There are lender qualifications for the buyer to meet,
                  including income verification. If a promising buyer can’t show creditworthiness
                  and ability to take on the financial responsibility of the loan, they could be
                  denied.
                </li>
                <li>
                  Upfront costs: There’s likely a difference between the loan balance and the home’s
                  current market value. That difference needs to be covered by the buyer. For
                  example, if the home is worth $600,000 and the assumable loan balance is $400,000,
                  the buyer needs to cover that $200,000 difference, typically through a cash
                  payment or a second loan.
                </li>
                <li>
                  Loan restrictions: As we’ve already touched on, not all home loans are assumable.
                  Before{" "}
                  <BlogLink
                    url="https://selbysellssd.com/real-estate-marketing-services"
                    text="marketing their homes"
                  />{" "}
                  with this competitive edge, sellers need to confirm that their existing mortgage
                  qualifies as an assumable loan.
                </li>
              </ul>
              <Heading type="h2">Achieve Your Real Estate Goals</Heading>
              <CommonParagraph>
                Assumable loans in San Diego are a win-win solution for both buyers and sellers. But
                they’re not the only way to achieve your real estate goals! Whether you’re looking
                for financing insights, want to explore neighborhoods or are ready to sell and start
                your next chapter, The Selby Team is here to help.{" "}
                <ContactSlideoutLink text="Connect with us" /> to get started!
              </CommonParagraph>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
